import React, {FC, useState} from 'react';
import {navigate} from 'gatsby';
import Section from '../section';
import UserWrap from '../userWrap';
import Button from '../../components/button';
import SubscriptionModal from '../../components/subscriptionModal';
import {getShowModalonClick, getUserExists, setShowModalonClick} from '../../helpers';
import shuttle from '../../images/svgIcons/shuttle.svg';
import robotIllustration from '../../images/hexomatic/Hexomatic-robot-graphic.jpg';

const FooterCTA: FC<{getStartedText?: string; getStartedLink?: string; img?: any; list?: boolean}> = ({
  getStartedText,
  getStartedLink,
  img,
  list,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Section className="row m-3">
        <div className="col-12 col-lg-6 my-auto">
          <h5 className="py-3">Automate and scale time-consuming tasks like never before</h5>
          <p>
            Create your own bots in minutes to extract data from any website and leverage 100+ ready made automations to
            scale time consuming tasks on autopilot.
          </p>
          <p>Hexomatic works 24/7 from the cloud, no complex software or coding required.</p>
          <div className="growth-hacking-link text-center">
            <UserWrap
              withoutUser={() => (
                <div className='text-md-left'>
                  <Button
                    primary
                    className="ltd_btn home-page-btn mt-2"
                    onClick={() =>
                      getStartedLink ? (window.location.href = getStartedLink) : navigate('/registration/')
                    }
                  >
                    {getStartedText || 'Get started in minutes'}
                  </Button>
                  <div
                    className="clickable-text"
                    onClick={() => {
                      if (getUserExists() === 'true' || getShowModalonClick() === 'false') {
                        window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                      } else {
                        setShowModalonClick('true');
                        setShow(true);
                      }
                    }}
                  >
                    Get our growth hacking playbook
                    <img src={shuttle} alt="shuttle" />
                  </div>
                </div>
              )}
              withLoading={() => (
                <div className='text-md-left'>
                  <Button
                    primary
                    className="ltd_btn home-page-btn"
                    onClick={() => (getStartedLink ? (window.location.href = getStartedLink) : navigate('/pricing/'))}
                  >
                    {getStartedText || 'Get started in minutes'}
                  </Button>
                  <div
                    className="clickable-text mt-2"
                    onClick={() => {
                      if (getUserExists() === 'true' || getShowModalonClick() === 'false') {
                        window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                      } else {
                        setShowModalonClick('true');
                        setShow(true);
                      }
                    }}
                  >
                    Get our growth hacking playbook
                    <img src={shuttle} alt="shuttle" />
                  </div>
                </div>
              )}
              withUser={user =>
                list ? (
                  <div className='text-md-left'>
                    <Button
                      primary
                      className="ltd_btn home-page-btn"
                      onClick={() => (getStartedLink ? (window.location.href = getStartedLink) : navigate('/pricing/'))}
                    >
                      {getStartedText || 'Get started in minutes'}
                    </Button>
                    <div
                      className="clickable-text mt-2"
                      onClick={() => {
                        if (getUserExists() === 'true' || getShowModalonClick() === 'false') {
                          window.open('https://hexomatic.com/the_growth_hacking_playbook.pdf', '_blank');
                        } else {
                          setShowModalonClick('true');
                          setShow(true);
                        }
                      }}
                    >
                      Get our growth hacking playbook
                      <img src={shuttle} alt="shuttle" />
                    </div>
                  </div>
                ) : (
                  <div className='text-md-left'>
                    <Button
                      primary
                      className="ltd_btn home-page-btn"
                      onClick={() => (window.location.href = getStartedLink || 'https://dash.hexomatic.com')}
                    >
                      Run workflow
                    </Button>
                  </div>
                )
              }
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 px-2 d-flex">
          <img
            src={img || robotIllustration}
            alt="Hexomatic. The no-code, data extraction"
            className="home-page-image"
            style={{maxWidth: '100%', margin: 'auto', maxHeight: '350px', width: 'auto'}}
          />
        </div>
      </Section>
      {show && <SubscriptionModal handleSetShow={(arg: boolean) => setShow(arg)} isModalOpen={show} />}
    </>
  );
};

export default FooterCTA;
